<template>
  <div class="col how_lci_help_you">
    <!-- Start Section One  -->
    <div class="row my-4 d-block main-card-print">
      <div class="my-4 d-none d-lg-block">
        <div class="col-sm-12 px-0 px-lg-3 d-flex align-items-center">
          <!-- //?Sequence  -->
          <card
            :height="`${userScore.sequence * 2.5 + 120}px`"
            style="
              background-color: #39c;
              border-radius: 0;
              border: 1px solid #ccc;
              width: 100%;
              max-height: 230px;
              min-height: 120px;
            "
            class="my-auto score"
          >
            <div class="d-flex flex-column my-auto">
              <!-- score title text -->
              <!-- Medium & larger screens text   -->
              <p
                class="mt-1 text-center text-white mb-0"
                style="font-size: 2rem"
              >
                {{ localization("Sequence") }}
              </p>
              <div
                class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
              >
                <!-- First Letter  -->
                <p class="letter mb-0 text-white">
                  {{ localization("Sequence shortcut") }}
                </p>
                <!-- Score Number  -->

                <!-- Large Screens  -->
                <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 50 is min font value -->
                <p
                  class="number mb-0 text-white"
                  :style="{
                    fontSize: (userScore.sequence / 28) * 25 + 65 + 'px'
                  }"
                >
                  {{ userScore.sequence != null ? userScore.sequence : 0 }}
                </p>
              </div>
            </div>
          </card>
          <!-- //?Precision   -->
          <card
            :height="`${userScore.precision * 2.5 + 120}px`"
            style="
              border-radius: 0;
              border: 1px solid #ccc;
              min-width: 25%;
              background-color: #3c3;
              max-height: 230px;
              min-height: 120px;
            "
            class="my-auto score"
          >
            <div class="d-flex flex-column my-auto">
              <!-- score title text -->

              <!-- Medium & larger screens text   -->
              <p
                class="mt-1 text-center text-white mb-0"
                style="font-size: 2rem"
              >
                {{ localization("precision") }}
              </p>

              <div
                class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
              >
                <!-- First Letter  -->
                <p class="letter mb-0 text-white">
                  {{ localization("precision shortcut") }}
                </p>
                <!-- Score Number  -->
                <!-- Large Screens  -->
                <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                <p
                  class="number mb-0 text-white"
                  :style="{
                    fontSize: (userScore.precision / 28) * 25 + 65 + 'px'
                  }"
                >
                  {{ userScore.precision != null ? userScore.precision : 0 }}
                </p>
              </div>
            </div>
          </card>
          <!--//? Technical Reasoning  -->
          <card
            :height="`${userScore.technical_reasoning * 2.5 + 120}px`"
            style="
              border-radius: 0;
              border: 1px solid #ccc;
              min-width: 25%;
              background-color: #f93;
              max-height: 230px;
              min-height: 120px;
            "
            class="my-auto score"
          >
            <div class="d-flex flex-column my-auto">
              <!-- score title text -->
              <!-- Medium & larger screens text   -->
              <p
                class="mt-1 text-center text-white mb-0"
                style="font-size: 1.6rem"
              >
                {{ localization("Technical Reasoning") }}
              </p>
              <div
                class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                  flex-column flex-md-row
                "
              >
                <!-- First Letter  -->
                <p class="letter mb-0 text-white">
                  {{ localization("Technical Reasoning shortcut") }}
                </p>
                <!-- Score Number  -->
                <!-- Large Screens  -->
                <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                <p
                  class="number mb-0 text-white"
                  :style="{
                    fontSize:
                      (userScore.technical_reasoning / 28) * 25 + 65 + 'px'
                  }"
                >
                  {{
                    userScore.technical_reasoning != null
                      ? userScore.technical_reasoning
                      : 0
                  }}
                </p>
              </div>
            </div>
          </card>
          <!--//? Confluence  -->
          <card
            :height="`${userScore.confluence * 2.5 + 120}px`"
            style="
              border-radius: 0;
              border: 1px solid #ccc;
              min-width: 25%;
              background-color: #c36;
              max-height: 230px;
              min-height: 120px;
            "
            class="my-auto score"
          >
            <div class="d-flex flex-column my-auto">
              <!-- score title text -->
              <!-- Medium & larger screens text   -->
              <p
                class="mt-1 text-center text-white mb-0"
                style="font-size: 2rem"
              >
                {{ localization("Confluence") }}
              </p>

              <div
                class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
              >
                <!-- First Letter  -->
                <p class="letter mb-0 text-white">
                  {{ localization("Confluence shortcut") }}
                </p>
                <!-- Score Number  -->
                <!-- Large Screens  -->
                <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                <p
                  class="number mb-0 text-white"
                  :style="{
                    fontSize: (userScore.confluence / 28) * 25 + 65 + 'px'
                  }"
                >
                  {{ userScore.confluence != null ? userScore.confluence : 0 }}
                </p>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- d-lg-none -->
      <div class="my-4  d-lg-none ">
        <div class="col-sm-12 d-flex justify-content-center flex-column">
          <!-- Sequence  -->
          <div class="d-flex align-items-center">
            <p class="label sequence main-label">
              {{ userScore.sequence != null ? userScore.sequence : 0 }}
            </p>
            <span style="font-size: 1.3rem; text-transform: capitalize">{{
              localization("Sequence")
            }}</span>
          </div>
          <div class="d-flex align-items-center">
            <p class="label precision main-label">
              {{ userScore.precision != null ? userScore.precision : 0 }}
            </p>
            <span style="font-size: 1.3rem; text-transform: capitalize">{{
              localization("precision")
            }}</span>
          </div>
          <!-- Technical Reasoning  -->
          <div class="d-flex align-items-center">
            <p class="label technical_reasoning main-label">
              {{
                userScore.technical_reasoning != null
                  ? userScore.technical_reasoning
                  : 0
              }}
            </p>
            <span style="font-size: 1.3rem">{{
              localization("Technical Reasoning")
            }}</span>
          </div>
          <!-- Confluence  -->
          <div class="d-flex align-items-center">
            <p class="label confluence main-label">
              {{ userScore.confluence != null ? userScore.confluence : 0 }}
            </p>
            <span style="font-size: 1.3rem; text-transform: capitalize">{{
              localization("Confluence")
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End Section One  -->
    <card>
      <!-- Start Section Two  -->
      <div class="section_two">
        <div class="our_title ">
          <img
            class="img"
            src="@/assets/images/icons/interrogation-mark.png"
            alt="add"
            draggable="false"
          />
          <h4 class="title" v-if="lciCardsContent[0] != null">
            <span v-html="lciCardsContent[0].body"></span>
          </h4>
        </div>
        <div class="description_p mt-3 mb-5" v-if="lciCardsContent[1] != null">
          <p class="desc" v-html="lciCardsContent[1].body"></p>
        </div>
      </div>
      <!-- End Section Two  -->

      <!-- Start Section Three  -->
      <div class="section_three">
        <div class="row">
          <div
            class="col-lg-3 col-md-4 col-sm-12"
            v-if="lciCardsContent[2] != null"
          >
            <div class="wrapper_content">
              <h5 class="titles text-capitalize">
                <span v-html="lciCardsContent[2].body"></span>
              </h5>
              <div v-if="lciCardsContent[7] != null">
                <p class="desc" v-html="lciCardsContent[7].body"></p>
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-4 col-sm-12"
            v-if="lciCardsContent[3] != null"
          >
            <div class="wrapper_content">
              <h5 class="titles text-capitalize">
                <span v-html="lciCardsContent[3].body"></span>
              </h5>
              <div v-if="lciCardsContent[8] != null">
                <p class="desc" v-html="lciCardsContent[8].body"></p>
              </div>
            </div>
          </div>
          <div
            class="col-lg-3 col-md-4 col-sm-12"
            v-if="lciCardsContent[4] != null"
          >
            <div class="wrapper_content">
              <h5 class="titles text-capitalize">
                <span v-html="lciCardsContent[4].body"></span>
              </h5>
              <div v-if="lciCardsContent[9] != null">
                <p class="desc" v-html="lciCardsContent[9].body"></p>
              </div>
            </div>
          </div>
          <div
            class="col-lg-3 col-md-4 col-sm-12"
            v-if="lciCardsContent[5] != null"
          >
            <div class="wrapper_content">
              <h5 class="titles text-capitalize">
                <span v-html="lciCardsContent[5].body"></span>
              </h5>
              <div v-if="lciCardsContent[10] != null">
                <p class="desc" v-html="lciCardsContent[10].body"></p>
              </div>
            </div>
          </div>
          <div
            class="col-lg-3 col-md-4 col-sm-12"
            v-if="lciCardsContent[6] != null"
          >
            <div class="wrapper_content">
              <h5 class="titles text-capitalize">
                <span v-html="lciCardsContent[6].body"></span>
              </h5>
              <div v-if="lciCardsContent[11] != null">
                <p class="desc" v-html="lciCardsContent[11].body"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Section Three  -->

      <!-- Start Section Four  -->

      <div class="text-center mt-4" v-if="lciCardsContent[12] != null">
        <p class="desc" v-html="lciCardsContent[12].body"></p>
      </div>
      <!-- End Section Four  -->
    </card>
  </div>
</template>

<script>
import { Card } from "src/components/index";
export default {
  components: {
    Card
  },
  computed: {
    userScore() {
      return this.$store.getters["user/score"];
    },
    lciCardsContent() {
      return this.$store.getters["user/get_lci_help_you_cards"];
    }
  },
  methods: {
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    getProgressValue(key) {
      switch (key) {
        case "Precision":
          return this.userScore.precision;
        case "Technical Reasoning":
          return this.userScore.technical_reasoning;
        case "Confluence":
          return this.userScore.confluence;
        case "Sequence":
          return this.userScore.sequence;
      }
    }
  },
  mounted() {
    this.$store.dispatch("user/user", this.auth().id);
    this.$store.dispatch("user/handlerLciHelpYouCards");
  }
};
</script>

<style lang="scss" scoped>
.how_lci_help_you {
  .letter {
    font-size: 40px;
  }
  .our_title {
    display: flex;
    gap: 8px;

    .img {
      width: 20px;
      height: 20px;
    }
    .title {
      color: #515151;
      margin: 0;
      text-transform: inherit;
      // font-size: ;
    }
  }
  .description_p {
    .desc {
      color: #515151;
      margin: 0;
    }
  }
  .section_three {
    .wrapper_content {
      border-top: 7px solid #00563f;
      padding-top: 10px;
      margin-bottom: 30px;
    }
    .titles {
      color: #515151;
      margin-bottom: 10px;
    }
    .desc {
      color: #777;
      font-size: 13px;
    }
    .lists {
      padding: 0 0 0 15px;
      li {
        color: #777;
        font-size: 13px;
      }
    }
  }
}
.label {
  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}
// Fixed Bug In Background Color In Medium And Small Screens
::v-deep .sequence {
  &.main-label {
    color: #fff;
    background-color: #39c;
    &::before {
      opacity: 0;
    }
  }
}
::v-deep .confluence {
  &.main-label {
    color: #fff;
    background-color: #c36;
    &::before {
      opacity: 0;
    }
  }
}
::v-deep .technical_reasoning {
  &.main-label {
    color: #fff;
    background-color: #f93;
    &::before {
      opacity: 0;
    }
  }
}
::v-deep .precision {
  &.main-label {
    background-color: #3c3;
    color: #fff;
    &::before {
      opacity: 0;
    }
  }
}
</style>
